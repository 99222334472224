function RightTitle() {
    return (
        <>
            <div className="title">
                聚集企业生产要素
            </div>
        </>
    );
}

export default RightTitle;