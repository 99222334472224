import React from 'react';

export const product1 = {
    title:"服务数字化转型",
    cards: [
        {
            cardId: 101,
            key: 101,
            cardTitle: "智能办公门户 AllOnline",
            cardImage: "oa",
            cardLogoAlt: "智能办公门户",
            cardDescription: "集通讯、协作、管理于一体，实现文件共享、日程同步、远程会议，打造高效、智能的企业办公新体验。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 102,
            key: 102,
            cardTitle: "企业门户网站 MabEngine",
            cardImage: "web",
            cardLogoAlt: "logo_des",
            cardDescription: "企业专属的数字化门面，信息发布与品牌传播的高效平台。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 103,
            key: 103,
            cardTitle: "员工通行卡 OnePass",
            cardImage: "onepass",
            cardLogoAlt: "logo_des",
            cardDescription: "一卡在手，万事无忧。支持实体卡、NFC 虚拟卡、APP，是集成身份验证与日常消费的便捷工具。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 104,
            key: 104,
            cardTitle: "统一登录（权限治理）中心 SSO",
            cardImage: "sso",
            cardLogoAlt: "logo_des",
            cardDescription: "单点登录，简化访问流程，提升企业系统安全性。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 105,
            key: 105,
            cardTitle: "统一身份认证 + 权限控制 IAM",
            cardImage: "iam",
            cardLogoAlt: "logo_des",
            cardDescription: "集中管理用户权限，保障企业数据安全与合规访问。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 106,
            key: 106,
            cardTitle: "电子流 BPM",
            cardImage: "bpm",
            cardLogoAlt: "logo_des",
            cardDescription: "优化企业流程管理，实现业务流程的自动化与智能化。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 107,
            key: 107,
            cardTitle: "办公云桌面 CloudDesk",
            cardImage: "clouddesk",
            cardLogoAlt: "logo_des",
            cardDescription: "云端办公环境，随时随地访问，保障数据安全与高效协作。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 108,
            key: 108,
            cardTitle: "项目管理系统 Tasker",
            cardImage: "tasker",
            cardLogoAlt: "logo_des",
            cardDescription: "项目管理全周期解决方案，提升团队协作效率与项目透明度。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 109,
            key: 109,
            cardTitle: "员工管理系统 HRM",
            cardImage: "hrm",
            cardLogoAlt: "logo_des",
            cardDescription: "人力资源数字化管理，简化招聘、培训、绩效评估流程。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 110,
            key: 110,
            cardTitle: "财务管理系统",
            cardImage: "finance",
            cardLogoAlt: "logo_des",
            cardDescription: "集成财务流程，实现预算、成本、收入的智能管理与分析。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 111,
            key: 111,
            cardTitle: "绩效管理工具 OKR/KPI",
            cardImage: "okr",
            cardLogoAlt: "logo_des",
            cardDescription: "目标与关键结果跟踪，驱动员工绩效与企业战略对齐。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 112,
            key: 112,
            cardTitle: "企业内网访问控制系统 AVN",
            cardImage: "vpn",
            cardLogoAlt: "logo_des",
            cardDescription: "强化网络安全，控制内网访问权限，保护企业信息资产。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 113,
            key: 113,
            cardTitle: "企业 IT 服务中台 ITSSC",
            cardImage: "itssc",
            cardLogoAlt: "logo_des",
            cardDescription: "IT 服务集中管理，快速响应企业技术需求与问题解决。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 114,
            key: 114,
            cardTitle: "行政服务中台 MCSSC",
            cardImage: "mcssc",
            cardLogoAlt: "logo_des",
            cardDescription: "一站式行政服务，提升企业后勤支持的效率与质量。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 115,
            key: 115,
            cardTitle: "组织架构管理 HAM",
            cardImage: "ham",
            cardLogoAlt: "logo_des",
            cardDescription: "清晰组织架构，优化部门协作，提升企业运营效率。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 116,
            key: 116,
            cardTitle: "员工职级管理 HLM",
            cardImage: "hlm",
            cardLogoAlt: "logo_des",
            cardDescription: "职级体系管理，优化员工晋升路径，提升组织效能。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 117,
            key: 117,
            cardTitle: "智能差旅系统 PAS",
            cardImage: "pas",
            cardLogoAlt: "logo_des",
            cardDescription: "差旅管理智能化，简化预订流程，控制差旅成本。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 118,
            key: 118,
            cardTitle: "定制化即时通信 IM",
            cardImage: "im",
            cardLogoAlt: "logo_des",
            cardDescription: "定制化通信解决方案，保障企业内部沟通的即时性与安全性。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 119,
            key: 119,
            cardTitle: "企业智能体（本地大模型）C3GPT",
            cardImage: "gpt",
            cardLogoAlt: "logo_des",
            cardDescription: "定制您的私有架属大模型，用丰富的企业知识、构建超强企业智能体。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 120,
            key: 120,
            cardTitle: "智慧员工 RPA",
            cardImage: "rpa",
            cardLogoAlt: "logo_des",
            cardDescription: "使用人工智能技术增强的 RPA。（注：RPA 指‘行为录制机器人’，使用 RPA 可有效提升生产效率）",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
    ]
}
export const product2 = {
    title: "汇聚企业智能",
    cards: [
        {
            cardId: 201,
            key: 201,
            cardTitle: "数字人 * 视频合成",
            cardImage: "virtual-person",
            cardLogoAlt: "logo_des",
            cardDescription: "服务内容创作行业，提供数字人 * 视频合成能力：让爆款视频，仅需一段文案；让爆款视频，也可以工业化。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 202,
            key: 202,
            cardTitle: "广汇数据聚合",
            cardImage: "data-merge",
            cardLogoAlt: "logo_des",
            cardDescription: "服务各行业的商业分析、商机分析、市场开发。提供多平台、多可靠数据源、多独家分析源聚合后的数据聚合服务。让您的企业在激烈市场竞争中占据信息优势。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 203,
            key: 203,
            cardTitle: "数据清洗平台",
            cardImage: "data-clean",
            cardLogoAlt: "logo_des",
            cardDescription: "服务人工智能产业、大数据计算产业。提供灵活、高效、稳定可靠的数据清洗能力。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 204,
            key: 204,
            cardTitle: "数据标注平台",
            cardImage: "data-tag",
            cardLogoAlt: "logo_des",
            cardDescription: "服务人工智能产业：基于行业需要的数据标注工具平台。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 205,
            key: 205,
            cardTitle: "数据交易平台",
            cardImage: "data-market",
            cardLogoAlt: "logo_des",
            cardDescription: "服务数字产业：搭建数据交易平台。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 206,
            key: 206,
            cardTitle: "客户关系管理系统 CRM",
            cardImage: "crm",
            cardLogoAlt: "",
            cardDescription: "服务各行业销售：灵活管理客户关系。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 207,
            key: 207,
            cardTitle: "数字业务营销埋点系统 SPM",
            cardImage: "spm",
            cardLogoAlt: "",
            cardDescription: "服务金融、服务、电子商务行业：网站 / 网店 / APP / IM，用户行为全关注。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 208,
            key: 208,
            cardTitle: "数字业务分析系统 SCM",
            cardImage: "scm",
            cardLogoAlt: "",
            cardDescription: "服务金融、服务、电子商务行业：基于用户行为数据，分析网站 / 网店 / APP 商品或活动的客户旅程，分析客户喜好。有效提升点击率、提升转化率",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 209,
            key: 209,
            cardTitle: "产品管理系统 PSM",
            cardImage: "psm",
            cardLogoAlt: "logo_des",
            cardDescription: "服务中、小型 ToB 云服务厂商：解决产品、版本管理所遇到的问题，提供优质的产品、生产环境、项目管理一站式工具。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 210,
            key: 210,
            cardTitle: "海外网店超级站 OverseasShop",
            cardImage: "super-shop",
            cardLogoAlt: "logo_des",
            cardDescription: "包含：满足海外消费者购物习惯的独立购物站、秒杀站、活动站，服务面向海外生易的供应链管理等应用。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        }
    ]
}
export const product3 = {
    title: "聚集企业生产要素",
    cards: [
        {
            cardId: 301,
            key: 301,
            cardTitle: "Web3 数字游民社区",
            cardImage: "web3-valley",
            cardLogoAlt: "logo_des",
            cardDescription: "我们在威海、青岛、厦门开办了数字游民社区，为有创业梦想 / 流动高品质办公需要的您提供有意义有价值的圈子。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 302,
            key: 302,
            cardTitle: "灵活用工人力资源服务",
            cardImage: "cloudy-human-resource",
            cardLogoAlt: "logo_des",
            cardDescription: "响应国家支持灵活就业政策号召，为有灵活就业需要的伙伴提供标准平台、为有临时人力需要的企业提供高质量、稳定、价格公道的人力资源服务。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 302,
            key: 32,
            cardTitle: "标准商业机会伙伴 BP Service",
            cardImage: "bp",
            cardLogoAlt: "logo_des",
            cardDescription: "我们搭建了基于入住企业所在地的商业圈：您可以基于您的生产需要，寻觅可靠商业伙伴、共建商业上下游链条。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        },
        {
            cardId: 303,
            key: 303,
            cardTitle: "标准商业合作伙伴 LP Service",
            cardImage: "lp",
            cardLogoAlt: "logo_des",
            cardDescription: "提供直达各路投资者的中介服务。好项目、值得好资本的关注、扶持。",
            cardLinkStatus: 1,
            cardLinkContent: "查看详情",
            cardLinkUrl: "https://www.alibaba.com"
        }
    ]
}
export const copyright = {
    "year": "2024",
    "chn": "承运企业智能",
    "eng": "Chain Cloud Enterprise Intelligence",
    "jpn": "Chain Cloud Enterprise Intelligence(株)",
    "kor": "Chain Cloud Enterprise Intelligence(株)",
}

function Data() {
    return (<></>);
}


export default Data;