import Card from "../../../../../../entity/card/Card.js";
import CardSmall from "../../../../../../entity/card/CardSmall.js";

function Cards(props) {
    const cards = Array.isArray(props.cards)? props.cards: [];
    return (
        <>
            <div className='cards cards-container'>
                {
                    cards.map((card, index) => (
                            <Card
                                key={index}
                                card_key={index}
                                card_title={card.cardTitle}
                                card_logo={card.cardImage}
                                card_logo_alt={card.cardLogoAlt}
                                card_description={card.cardDescription}
                                card_link_status={card.cardLinkStatus}
                                card_link_content={card.cardLinkContent}
                                card_link_url={card.cardLinkUrl}
                            >
                                {index}
                            </Card>

                        )
                    )
                }
                <CardSmall
                    name = { props.name }
                    url  = { props.url }
                />
            </div>
        </>
    );
}


export default Cards;