import '../../assets/css/entity/card/layout.css';
import '../../assets/css/entity/card/element-style.css';

function CardSmall({name, url}) {
    return (
        <div className="card-small">
            <p>
                <a href={url}>{name}</a>
            </p>
        </div>
    );
}

export default CardSmall;