import {useState} from "react";

import Head from './layout/Head.js';
import Body from './layout/Body.js';
import Foot from './layout/Foot.js';
import Data from '../../data/Data.js';
import Solution from "../../entity/navigator/hover/solution/Solution.js";
import Application from "../../entity/navigator/hover/application/Application.js";

import '../../assets/css/page/index/index.css'

const data = Data;

function Index() {
    const [isPop1Visible, setPop1Visible] = useState(false);
    const [isPop2Visible, setPop2Visible] = useState(false);
    // const [isPop3Visible, setPop3Visible] = useState(false);

    return (
        <>
            <Head
                open1 = {() => { setPop1Visible(true) }}
                close1 = {() => { setPop1Visible(false) }}
                open2 = {() => { setPop2Visible(true) }}
                close2 = {() => { setPop2Visible(false) }}
            />

            <Body
                data={data}
            />

            <Foot
                // open={() => { setPop3Visible(true) }}
                // close={() => { setPop3Visible(false) }}
            />
            <>
                {/* <PopUp1 />*/}
                { isPop1Visible
                    &&
                    <Solution
                        closePopup={
                            () => { setPop1Visible(false); setPop2Visible(false); }
                        }
                    />
                }
                {/* <PopUp2 />*/}
                { isPop2Visible
                    &&
                    <Application closePopup={
                        () => { setPop1Visible(false); setPop2Visible(false); }
                    }
                    />
                }
                {/*/!* <PopUp3 />*!/*/}
                {/*{ isPop3Visible*/}
                {/*    &&*/}
                {/*    <About closePopup={*/}
                {/*        () => { setPop3Visible(false);}*/}
                {/*    }*/}
                {/*    />*/}
                {/*}*/}
            </>
        </>
    );
}

export default Index;