import '../../../../assets/css/entity/hover/layout.css';
import HeadHover2 from "../../../hover/head-hover2/HeadHover2.js";

function Application({closePopup}) {
    return (
        <>
            <div
                className="navigator-hover"
                onMouseLeave={closePopup}
            >
                <HeadHover2 />
            </div>
        </>
    );
}

export default Application;
