function NavigatorSearch({closeAllPopUp}) {
    return (
        <>
            <div
                className="navigator-search"
                onMouseEnter={closeAllPopUp}
            >
                <input
                    placeholder="搜索解决方案、产品_"
                />
            </div>
        </>
    );
}

export default NavigatorSearch;