import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Index from "./pages/index/Index.js";
import Languages from "./pages/languages/Languages.js";
import './assets/css/style.css';
import Login from "./pages/login/Login";
import Help from "./pages/help/Help"; // load style sheet one-time

const router = createBrowserRouter([
    {
        path: "/",
        element: ( <Index />),
    },
    {
        path: "/index.htm",
        element: ( <Index />),
    },
    {
        path: "/index.html",
        element: ( <Index />),
    },
    // 多语言
    {
        path: "/languages.html",
        element: ( <Languages />),
    },
    {
        path: "/languages",
        element: ( <Languages />),
    },
    // 客服、支持

    // 帮助、文档
    {
        path: "/help.html",
        element: ( <Help />),
    },
    {
        path: "/help",
        element: ( <Help />),
    },
    // 登录
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/login.html",
        element: <Login />
    }
]);

function RouteMap () {
    return (
        <RouterProvider router={ router } />
    );
}

export default RouteMap;