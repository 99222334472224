import '../../assets/css/entity/footer/layout.css'
import '../../assets/css/entity/footer/elements-style.css'
import FooterCopyright from "./FooterCopyright.js";
import FooterAboutUs from "./FooterAboutUs.js";
import FooterJoinUs from "./FooterJoinUs.js";
import FooterSocialResponsibility from "./FooterSocialResponsibility.js";
import FooterICP from "./FooterICP.js";


function Footer({open, close}) {
    return (
        <>
            <div className="footer">
                <FooterCopyright />
                <span className='footer-middle-0 footer-borderline' />
                <FooterAboutUs openPopup={() => open()} />
                <FooterJoinUs />
                <FooterSocialResponsibility />
                <FooterICP />
            </div>

        </>
    );
}

export default Footer;