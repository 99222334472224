import '../../assets/css/entity/card/layout.css'
import '../../assets/css/entity/card/element-style.css'

function CardTitle({title}) {
    return (
        <div className="card-right-title">
            <p className="card-title">{title}</p>
        </div>
    );
}

export default CardTitle;