import BodyLeft from "./body/left/BodyLeft.js";
import BodyRight from "./body/right/BodyRight.js";

function Body (prop) {
    return (
        <>
            <BodyLeft prop={prop}/>
            <BodyRight prop={prop} />
        </>
    );
}

export default Body;