import CardLogo from "./CardLogo.js";

function CardLeft({card_logo, card_logo_alt}) {
    return (
        <div className='card-left'>
            <CardLogo
                img_url={card_logo}
                img_alt={card_logo_alt}
            />
        </div>
    );
}

export default CardLeft;