import DotButtonLeft from "../button/DotButtonLeft.js";
import DotButtonRight from "../button/DotButtonRight.js";

function Banner2({before, after, to}) {
    return (
        <>
            <div className="banner-outbound banner-container-inner banner2">
                <DotButtonLeft call={() => before()}/>
                <div className="banner-title">
                    <h2>为企业升级</h2>
                </div>
                <div className="banner-describe">
                    <p>挖掘企业潜力，提升专属竞争力</p>
                </div>
                <div className="banner-info">
                    <p>数字化转型不仅是技术的革新，也是战略的飞跃。它赋予企业透视市场风云的慧眼，以数据为舵，创新为帆，驶向可持续增长的蓝海。</p>
                    <p>通过数据分析与人工智能，企业能够解锁隐藏的增长潜能，不断推出颠覆性的产品和服务，引领行业前行。</p>
                </div>
                <div className="banner-area">
                    <p>北京 · 国贸 CBD、中国中央广播电视总台</p>
                </div>
                <DotButtonRight call={() => after()}/>
            </div>
        </>
    );
}

export default Banner2;