function BodyRight() {
    return (
        <>
            <div className="body-right body">
                <div>
                    <h2 className="language-title">已支持的国家/区域、语言</h2>
                </div>
                <div className="groups">
                    <div className="group">
                        <h3 className="group-title">亚洲、大洋洲 / Asia Pacific</h3>
                        <div className="country-regions">
                            <span className="country-region">
                                <p className="group-country-region-name">中国 / 中国大陆</p>
                                <p className="group-country-region-language">
                                    <a href="/">简体中文</a>
                                    <a href="/ch/ko">조선말</a>
                                    <a href="/ch/tibet">བོད་སྐད་</a>
                                </p>
                            </span>
                            <span className="country-region">
                                <p className="group-country-region-name">中國香港 / 中国香港</p>
                                <p className="group-country-region-language">
                                    <a href="/hk/">繁体中文</a>
                                    <a href="/hk/en">English</a>
                                </p>
                            </span>
                            <span className="country-region">
                                <p className="group-country-region-name">中國澳门 / 中国澳门</p>
                                <p className="group-country-region-language">
                                    <a href="/mo/">繁体中文</a>
                                    <a href="/mo/en">English</a>
                                </p>
                            </span>
                            <span className="country-region">
                                <p className="group-country-region-name">中國台灣 / 中国台灣</p>
                                <p className="group-country-region-language">
                                    <a href="/tw/">繁体中文</a>
                                </p>
                            </span>
                            <span className="country-region">
                                <p className="group-country-region-name">Australia / 澳大利亚</p>
                                <p className="group-country-region-language">
                                    <a href="/au/">English</a>
                                </p>
                            </span>
                            <span className="country-region">
                                <p className="group-country-region-name">日本 / 日本</p>
                                <p className="group-country-region-language">
                                    <a href="/jp/">日本語</a>
                                </p>
                            </span>
                            <span className="country-region">
                                <p className="group-country-region-name">한국 / 韩国</p>
                                <p className="group-country-region-language">
                                    <a href="/kr/">한국어</a>
                                </p>
                            </span>
                        </div>
                    </div>
                </div>


                {/*<div>*/}
                {/*    <h3>欧洲</h3>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <h3>北美洲</h3>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <h3>北美洲</h3>*/}
                {/*</div>*/}
            </div>
        </>
    );
}

export default BodyRight;