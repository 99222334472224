import CardLeft from "./CardLeft.js";
import CardRight from "./CardRight.js";

import '../../assets/css/entity/card/layout.css';

function Card({card_logo, card_logo_alt, card_title, card_description}) {
    return (
        <div className="card">
            <CardLeft
                card_logo={card_logo}
                card_logo_alt={card_logo_alt}
            />
            <CardRight
                title={card_title}
                description={card_description}
            />
        </div>
    );
}

export default Card;