import '../../../../../assets/css/page/login/login.css'

function BodyLeft() {
    return (
      <>
          <div className="page-login-body-left page-login-body-left-flashing-element page-login-body-left-streamlined-effect">
              <div className="page-login-title">
                  <div className="title-title">
                      <h1>登录</h1>
                  </div>
                  <div className="title-describe">
                      <div className="typewriter-effect">
                          链接创新动力，激活产业价值&nbsp;
                      </div>
                  </div>
              </div>
          </div>
      </>
    );
}

export default BodyLeft;