import DotButtonLeft from "../button/DotButtonLeft.js";
import DotButtonRight from "../button/DotButtonRight.js";

function Banner1({before, after, to}) {
    return (
        <>
            <div className="banner-outbound banner-container-inner banner1">
                <DotButtonLeft call={() => before()}/>
                <div className="banner-title">
                    <h2>服务出海</h2>
                </div>
                <div className="banner-describe">
                    <p>创新驱动发展、优质优化运营</p>
                </div>
                <div className="banner-info">
                    <p>数字化转型不仅是技术的革新，也是战略的飞跃。它赋予企业透视市场风云的慧眼，以数据为舵，创新为帆，驶向可持续增长的蓝海。</p>
                    <p>通过数据分析与人工智能，企业能够解锁隐藏的增长潜能，不断推出颠覆性的产品和服务，引领行业前行。</p>
                </div>
                <div className="banner-area">
                    <p>上海 · 东方明珠</p>
                </div>
                <DotButtonRight call={() => after()}/>
            </div>
        </>
    );
}

export default Banner1;