import '../../assets/css/entity/navigator/layout.css';
import '../../assets/css/entity/navigator/element.css';

import NavigatorLogo from "./NavigatorLogo.js";
import NavigatorMenu from "./NavigatorMenu.js";
import NavigatorSearch from "./NavigatorSearch.js";
import NavigatorHelp from "./NavigatorHelp.js";
function Navigator({open1, close1, open2, close2}) {

    return (
        <>
            <div className="navigator">
                <NavigatorLogo closeAllPopUp={() => {close1(); close2();}}/>
                <NavigatorMenu openPopup1={() => {close2(); open1()}} openPopup2={() => {close1(); open2();}}/>
                <NavigatorSearch closeAllPopUp={() => {close1(); close2();}}/>
                <NavigatorHelp closeAllPopUp={() => {close1(); close2();}}/>
            </div>
        </>
    );
}

export default Navigator;