import "../../assets/css/entity/card/layout.css";
import "../../assets/css/entity/card/element-style.css";
import Image from "../../assets/image/objects/Image.js";

function CardLogo({img_url, img_alt}) {
    return (
        <div>
            <Image url = {img_url} alt = {img_alt} classname = "card-logo" />
        </div>
    );
}

export default CardLogo;