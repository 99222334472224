import Products from "./Product.js";
import {product1 as p1} from "../../../../../../data/Data.js";
import {product2 as p2} from "../../../../../../data/Data.js";
import {product3 as p3} from "../../../../../../data/Data.js";

function Catalog (prop) {
    const product1 = p1;
    const product2 = p2;
    const product3 = p3;

    return (
        <>
            <div className='catalog'>
                <Products product={product1} name="更多产品" url="/solution/transform"/>
                <Products product={product2} name="更多产品" url="/solution/digital-power"/>
                <Products product={product3} name="更多服务" url="/solution/process-elements"/>
            </div>
        </>
    );
}

export default Catalog;