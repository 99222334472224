import '../../assets/css/entity/button/layout.css';
import '../../assets/css/entity/button/element-style.css';
import ButtonLeft from '../../assets/image/dot-button-left.png';

function DotButtonLeft({call}) {
    return (
        <>
            <div className="dot-button dot-button-left" onClick={call}>
                <img src={ButtonLeft} alt="上一个"/>
            </div>
        </>
    );
}

export default DotButtonLeft;