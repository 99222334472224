import '../../assets/css/entity/navigator/layout.css';
import '../../assets/css/entity/navigator/element.css';

function NavigatorMenu({option, openPopup1, openPopup2}) {

    switch (option) {
        case 1: {
            return (
                <>
                    <div
                        className="navigator-1 navigator-small-screen navigator-middle-screen navigator-big-screen navigator-mega-screen"
                        onMouseEnter={openPopup1}
                        // onMouseLeave={handleMouseLeave1}
                    >
                        <p>
                            <a href="/solutions" className="navigator-link">解决方案</a>
                        </p>
                    </div>
                    <div
                        className="navigator-2 navigator-small-screen navigator-middle-screen navigator-big-screen navigator-mega-screen"
                        onMouseEnter={openPopup2}
                        // onMouseLeave={handleMouseLeave2}
                    >
                        <p>
                            <a href="/store" className="navigator-link">应用市场</a>
                        </p>
                    </div>
                </>
            );
        }
        case 2: {
            return (
                <>
                    <div
                        className="navigator-1 navigator-small-screen navigator-middle-screen navigator-big-screen navigator-mega-screen"
                        onMouseEnter={openPopup1}
                        // onMouseLeave={handleMouseLeave1}
                    >
                        <p>
                            <a href="/solutions" className="navigator-link">解决方案</a>
                        </p>
                    </div>
                    <div
                        className="navigator-2 navigator-small-screen navigator-middle-screen navigator-big-screen navigator-mega-screen"
                        onMouseEnter={openPopup2}
                        // onMouseLeave={handleMouseLeave2}
                    >
                        <p><a href="/store" className="navigator-link">应用市场</a></p>
                    </div>
                </>
            );
        }
        default: {
            return (
                <>
                    <div
                        className="navigator-1 navigator-small-screen navigator-middle-screen navigator-big-screen navigator-mega-screen"
                        onMouseEnter={openPopup1}
                        // onMouseLeave={handleMouseLeave1}
                    >
                        <p>
                            <a href="/solutions" className="navigator-link">解决方案</a>
                        </p>
                    </div>
                    <div
                        className="navigator-2 navigator-small-screen navigator-middle-screen navigator-big-screen navigator-mega-screen"
                        onMouseEnter={openPopup2}
                        // onMouseLeave={handleMouseLeave2}
                    >
                        <p>
                            <a href="/store" className="navigator-link">应用市场</a>
                        </p>
                    </div>
                </>
            );
        }
    }
}

export default NavigatorMenu;