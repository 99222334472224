import ProductTitle from "./ProductTitle.js";
import Cards from "../body/Cards.js";

function Products (props) {
    const {title, cards} = props.product;
    const name = props.name;
    const url  = props.url;
    return (
        <>
            <div className='products'>
                <ProductTitle title={ title } />
                <Cards
                    cards = { cards }
                    name  = { name  }
                    url   = { url  }
                />
            </div>
        </>
    );
}

export default Products;