import '../../../../../assets/css/page/login/login.css'

function BodyRight() {
    return (
        <>
            <div className="page-login-body-right">
                <div className="page-login-body-right-title">
                    <h2>登录</h2>
                </div>
                <div className="page-login-body-right-forum">
                    <span>
                        <input placeholder="输入账号：邮箱、电话 " id="username" name="username"/>
                    </span>
                    <span>
                        <input placeholder="输入密码" type="password" id="password" name="password"/>
                    </span>
                    <span>
                        <button>登录</button>
                    </span>
                </div>
                <div className="page-login-body-right-help-panel">
                    <span><p><a href="/account/register">注册账号</a></p></span>
                    <span><p></p></span>
                    <span><p><a href="/account/login/forget-username">忘记账号名</a>&nbsp;&nbsp;&nbsp;<a href="/account/login/forget-password">找回密码</a></p></span>
                </div>
                <div className="page-login-body-right-third-login">
                    <span><p><a href="/account/login/message">短信登录</a></p></span>
                    <span><p></p></span>
                    <span><p>三方账号登录: <a href="/account/login/wechat">微信</a> / <a href="/account/login/dingtalk">钉钉</a> / <a href="/account/login/lark">飞书</a></p></span>
                </div>
                <div className="page-login-body-right-agreements">
                    <p>
                        登录视为您已同意&nbsp;
                        <a href="/account/agreements-for/3rd-party-account-binding.html">第三方账号绑定协议</a>、
                        <a href="/account/agreements-for/user.html">用户协议</a>、
                        <a href="/account/agreements-for/privacy.html">隐私政策</a>、
                        <a href="/account/agreements-for/product-and-service.html">产品服务协议</a> 。
                    </p>
                </div>
            </div>
        </>
    );
}

export default BodyRight;