import Footer from '../../../entity/footer/Footer.js';

function Foot({open, close}) {
    return (
        <>
            <Footer
                open={() => open()}
                close={() => close()}
            />
        </>
    );
}

export default Foot;