import Banner1 from "../../../../../entity/banner/Banner1.js";
import Banner2 from "../../../../../entity/banner/Banner2.js";
import Banner3 from "../../../../../entity/banner/Banner3.js";
import React, {useState} from "react";

function Banners() {
    const [activeIndex, setActiveIndex] = useState(0);
    const maxSize = 3; // size = 3
    //
    const abs  = (value) => {return (value < 0)?  (value + maxSize): value;}
    const next = () => setActiveIndex(abs(activeIndex + 1) % maxSize);
    const prev = () => setActiveIndex(abs(activeIndex - 1) % maxSize);
    console.log(activeIndex)
    const goTo = (index) => {setActiveIndex(index);};
    //
    const banners = [
        <Banner1 before={() => prev()} after={() => next()} to={(i) => goTo(i)}/>,
        <Banner2 before={() => prev()} after={() => next()} to={(i) => goTo(i)}/>,
        <Banner3 before={() => prev()} after={() => next()} to={(i) => goTo(i)}/>
    ];
    //
    return (
        <div className="banner-carousel banner-container">
            {
                banners.map((BannerComponent, index) => (
                    <div
                        key={index}
                        className={index === activeIndex ? 'active' : ''}
                    >
                        {BannerComponent}
                    </div>
                ))
            }
        </div>
    );
}

export default Banners;