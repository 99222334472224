import "../../../assets/css/entity/hover/layout.css"

function HeadHover2() {
    return (
        <>
            <div className="hover-area hover-area-layout hover-area-layout-2">
                <div className="hover-group">
                    <div className="hover-banner hover-banner2">
                        <div className="hover-banner-title">
                            <h4>应用市场</h4>
                        </div>
                        <div className="hover-banner-desc">
                            <p>企业级应用商店，<br />企业版的“app store”。</p>
                        </div>
                        <div className="hover-banner-link">
                            <p><a href="/store">更多 >></a></p>
                        </div>
                    </div>
                </div>
                <div className="hover-group">
                    <div className="hover-title">
                        <h4>云端企业总部</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/product/all-online">智能办公门户 AllOnline</a></li>
                            <li><a href="/product/mab-engine">企业门户网站 MabEngine</a></li>
                            <li><a href="/product/one-pass">员工通行卡 OnePass</a></li>
                            <li><a href="/product/sso">统一登录（权限治理）中心 SSO</a></li>
                            <li><a href="/product/bpm">电子流 BPM</a></li>
                            <li><a href="/product/cloud-desk">办公云桌面 CloudDesk</a></li>
                        </ul>
                    </div>

                </div>
                <div className="hover-group">
                    <div className="hover-title">
                        <h4>&nbsp;</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/product/finance">财务管理系统</a></li>
                            <li><a href="/product/tasker">项目管理系统 Tasker</a></li>
                            <li><a href="/product/itssc">IT 服务中台 ITSSC</a></li>
                            <li><a href="/product/iam">统一身份认证 + 权限控制 IAM</a></li>
                            <li><a href="/product/okr">绩效管理工具 OKR/KPI</a></li>
                            <li><a href="/product/im">企业专有即时通信 IM</a></li>
                        </ul>
                    </div>
                    <div className="hover-foot">
                        <ul>
                            <li>&nbsp;</li>
                        </ul>
                    </div>
                </div>
                <div className="hover-group">
                    <div className="hover-title">
                        <h4>&nbsp;</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/product/hrm">员工管理系统 HRM</a></li>
                            <li><a href="/product/ham">组织架构管理 HAM</a></li>
                            <li><a href="/product/hlm">员工职级管理 HLM</a></li>
                            <li><a href="/product/pas">智能差旅系统 PAS</a></li>
                            <li><a href="/product/avn">企业内网访问控制系统 AVN</a></li>
                            <li><a href="/product/weki">企业知识管理 WeKi</a></li>
                        </ul>
                    </div>
                    <div className="hover-foot">
                        <ul>
                            <li>&nbsp;</li>
                        </ul>
                    </div>
                </div>
                <div className="hover-group">
                    <div className="hover-title">
                        <h4>业务开拓百宝箱</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/product/rpa">智慧员工 RPA</a></li>
                            <li><a href="/product/vr">数字人 * 视频合成</a></li>
                            <li><a href="/product/psm">产品管理系统 PSM</a></li>
                            <li><a href="/product/spm">数字业务营销埋点系统 SPM</a></li>
                            <li><a href="/product/scm">数字业务分析系统 SCM</a></li>
                        </ul>
                    </div>

                </div>

                <div className="hover-group">
                    <div className="hover-title">
                        <h4>公司出海工具箱</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/product/shop">海外网店超级站 OverseasShop</a></li>
                            <li><a href="/product/cmps">内容营销大本营 CMPS</a></li>
                            <li><a href="/product/emb">广汇数据聚合</a></li>
                            <li><a href="/product/dmt">数据（数据集、数据模型）交易平台</a></li>
                        </ul>
                    </div>

                </div>
                <div className="hover-group">
                    <div className="hover-title">
                        <h4>提供生产要素</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/product/wonder-land">数字游民社区</a></li>
                            <li><a href="/product/pp">灵活用工服务</a></li>
                            <li><a href="/product/bp">天使轮、A轮投资中介</a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </>
    );
}

export default HeadHover2;