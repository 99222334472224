import "../../assets/css/entity/banner/banner-language1.css";

function Banner4({before, after, to}) {
    return (
        <>
            <div className="banner-outbound banner-container-inner banner4">
                <div className="banner-title">
                    <h2>Language <br/> 多语言</h2>
                </div>
                <div className="banner-area">
                    <p>沈阳 · 沈阳故宫(ShenYang - The palace)</p>
                </div>
            </div>
        </>
    );
}

export default Banner4;