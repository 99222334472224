import RightTitle from "./title/RightTitle.js";
import Catalog from "./catalog/Catalog.js";

function BodyRight (prop) {
    return (
        <>
            <div className='body-right body'>
                <RightTitle/>
                <Catalog prop={prop}/>
            </div>
        </>
    );
}

export default BodyRight;