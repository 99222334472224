import '../../assets/css/entity/card/layout.css';
import '../../assets/css/entity/card/element-style.css';

function CardDescription({ description })  {
    return (
        <div className="card-right-description card-description">
            {description}
        </div>
    );
}

export default CardDescription;