import Banners from "./Banners.js";

function BodyLeft (prop) {
    return (
        <>
            <Banners />
        </>
    );
}

export default BodyLeft;