import CardTitle from "./CardTitle.js";
import CardDescription from "./CardDescription.js";

function CardRight({title, description}) {
    return (
        <div className='card-right'>
            <CardTitle title={title} />
            <CardDescription description={description} />
        </div>
    );
}

export default CardRight;