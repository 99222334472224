import {useEffect, useState} from "react";

import Head from './layout/Head.js';
import Body from './layout/Body.js';
import Foot from './layout/Foot.js';
import Solution from "../../entity/navigator/hover/solution/Solution.js";
import Application from "../../entity/navigator/hover/application/Application.js";

import '../../assets/css/page/languages/languages.css';

function Languages() {
    useEffect(() => {
        document.title = 'Switch Languages | 多语言 - CloudChain';
    }, []);
    const [isPop1Visible, setPop1Visible] = useState(false);
    const [isPop2Visible, setPop2Visible] = useState(false);

    return (
        <>
            <Head
                open1 = {() => { setPop1Visible(true) }}
                close1 = {() => { setPop1Visible(false) }}
                open2 = {() => { setPop2Visible(true) }}
                close2 = {() => { setPop2Visible(false) }}
            />
            <Body />
            <Foot />
            <>
                {/* <PopUp1 />*/}
                { isPop1Visible
                    &&
                    <Solution
                        closePopup={
                            () => { setPop1Visible(false); setPop2Visible(false); }
                        }
                    />
                }
                {/* <PopUp2 />*/}
                { isPop2Visible
                    &&
                    <Application closePopup={
                        () => { setPop1Visible(false); setPop2Visible(false); }
                    }
                    />
                }
                {/*/!* <PopUp3 />*!/*/}
                {/*{ isPop3Visible*/}
                {/*    &&*/}
                {/*    <About closePopup={*/}
                {/*        () => { setPop3Visible(false);}*/}
                {/*    }*/}
                {/*    />*/}
                {/*}*/}
            </>
        </>
    );
}

export default Languages;