import translatorLogoImage from "../../assets/image/translate.png";
import supportLogoImage from "../../assets/image/support.png";
import helpLogoImage from "../../assets/image/help.png";
import accountLogoImage from "../../assets/image/account.png";

import '../../assets/css/entity/navigator/layout.css';

function NavigatorHelp({closeAllPopUp}) {
    return (
        <>
            <div onMouseEnter={closeAllPopUp}>
                <div className="navigator-global navigator-small-screen">
                    <a href="/languages.html">
                        <img src={translatorLogoImage}
                             alt='Switch Languages / 切换语言'
                        />
                    </a>
                </div>
                <div className="navigator-custom-service navigator-small-screen">
                    <a href="/support.html">
                        <img src={supportLogoImage}
                             alt='客户服务'
                        />
                    </a>
                </div>
                <div className="navigator-help navigator-small-screen">
                    <a href="/help.html">
                        <img src={helpLogoImage}
                             alt='帮助'
                        />
                    </a>
                </div>
                <div className="navigator-user navigator-small-screen">
                    <a href="/login">
                        <img src={accountLogoImage}
                             alt='用户'
                             height="20vh"
                             width="20vw"
                        />
                    </a>
                </div>
            </div>
        </>
    );
}

export default NavigatorHelp;