import BodyLeft from "./body/left/BodyLeft";
import BodyRight from "./body/right/BodyRight";

function Body() {
    return (
        <>
            <BodyLeft />
            <BodyRight />
        </>
    );
}

export default Body;