import "../../../assets/css/entity/hover/layout.css"

function HeadHover1() {
    return (
        <>
            <div className="hover-area hover-area-layout hover-area-layout-1">
                <div className="hover-group">
                    <div className="hover-banner hover-banner1">
                        <div className="hover-banner-title">
                            <h4>解决方案</h4>
                        </div>
                        <div className="hover-banner-desc">
                            <p>协助企业发现问题，帮助企业解决问题。</p>
                        </div>
                        <div className="hover-banner-link">
                            <p><a href="/solutions">更多 >></a></p>
                        </div>
                    </div>
                </div>
                <div className="hover-group">
                    <div className="hover-title">
                        <h4>企业提效</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/solution/erp">企业资源规划（ERP）：整合信息流，打通业务流</a></li>
                            <li><a href="/solution/oversea-business">跨境电子商务：海外生意工具箱</a></li>
                            <li><a href="/solution/management-magic-power">智能办公：协助企业主管理方法落地</a></li>
                            <li><a href="/solution/smart-factory">智慧工厂：制造业升级、物流升级与供应链升级</a></li>
                            <li><a href="/solution/maintain-business-health">客户关系管理（CRM）：维护商誉第一步</a></li>
                            <li><a href="/solution/make-a-desicion">商业分析：提供决策支持的数据依靠</a></li>
                        </ul>
                    </div>
                </div>
                <div className="hover-group">
                    <div className="hover-title">
                        <h4>发展信息化业务</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/solution/digital-intellgence-for-all-data-lifecycle">数据智能：覆盖数据采集、清洗、标注、数据集交易、模型训练、模型智能体的全生命周期，助力数据智能业务发展</a></li>
                            <li><a href="/solution/application-business">移动业务：从 APP、小程序的开发，到提升全平台转化率的运营分析</a></li>
                            <li><a href="/solution/mcn">内容生产与内容营销：一个人，也可以是 MCN</a></li>
                        </ul>
                    </div>
                </div>
                <div className="hover-group">
                    <div className="hover-title">
                        <h4>企业生产要素</h4>
                    </div>
                    <div className="hover-body">
                        <ul>
                            <li><a href="/solution/wonder-land">数字游民社区：自主创业孵化中心 / 青年社交 / 数字游民部落</a></li>
                            <li><a href="/solution/pp">灵活用工：与属地人社局、残疾人联合会及属地院校合作，提供优廉的人力资源服务</a></li>
                            <li><a href="/solution/virtual-worker">数字员工：集成大模型、具有人工智能的流程自动化机器人员工（RPA）</a></li>
                            <li><a href="/solution/require-or-become-the-business-investment">投资者服务：帮助 LP、GP 与优秀企业建立连接</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeadHover1;