import '../../../../assets/css/entity/hover/layout.css';
import HeadHover1 from "../../../hover/head-hover1/HeadHover1.js";

function Solution({closePopup}) {
    return (
        <>
            <div
                className="navigator-hover"
                onMouseLeave={closePopup}
            >
                <HeadHover1 />
            </div>
        </>
    );
}

export default Solution;
