function ProductTitle({title}) {
    return (
        <>
            <h2 className='product-title'>
                { title }
            </h2>
        </>
    );
}

export default ProductTitle;