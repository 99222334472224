import {copyright as c} from '../../data/Data';

function FooterCopyright() {
    return (
        <span className='footer-left footer-copyright'>
            &copy; {c.year} {c.chn}
        </span>
    );
}

export default FooterCopyright;