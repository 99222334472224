import NotFoundImage from "./not-found.png";
import oa from './oa/oa5_small.jpg';
import web from './website/wen_small.jpg';
import onepass from './onepass/onepass_small.jpg';
import iam from './iam/iam3_small.jpg';
import sso from './sso/sso2_small.jpg';
import bpm from './bpm/bpm2_small.jpg';
import clouddesk from './clouddesk/clouddesk2_small.jpg';
import tasker from './tasker/tasker2_small.jpg';
import hrm from './hrm/hrm2_small.jpg';
import finance from './finance/finance_small.jpg';
import okr from './okr-kpi/okr2_small.jpg';
import vpn from './vpn/vpn_small.jpg';
import hlm from './hrm/hrm4_small.jpg';
import ham from './hrm/hrm6_small.jpg';
import itssc from './ssc/itssc_small.jpg';
import mcssc from './ssc/mcssc2_small.jpg';
import pas from './pas/pas_small.jpg';
import im from './im/im_small.jpg';
import gpt from './gpt/gpt3_small.jpg';
import rpa from './rpa/rpa2_small.jpg';
import virtualPerson from './virtual-person/virtual-person2_small.jpg';
import dataMerge from './data-merge/data-merge_small.jpg';
import dataClean from './data-clean/data-clean_small.jpg';
import dataMarket from './data-market/data-market2_small.jpg';
import dataTag from './data-tag/data-tag_small.jpg';
import crm from './crm/crm2_small.jpg';
import spm from './spm/spm_small.jpg';
import scm from './scm/scm_small.jpg';
import psm from './psm/psm_small.jpg';
import superShop from './super-shop/super-shop_small.jpg';
import web3Alley from './web3-valley/web3-alley_small.jpg';
import cloudHumanResource from './cloud-human-resource/cloud-human-resource_small.jpg';
import bp from './bp-lp/bp_small.jpg';
import lp from './bp-lp/lp_small.jpg';

function Image ({url, alt, classname}) {
    return getImage(url, alt, classname);
}

function getImage(url, alt, classname) {
    switch (url) {
        case "oa": {
            return <img src={oa} alt={alt} className={classname}/>;
        }
        case "web": {
            return <img src={web} alt={alt} className={classname}/>;
        }
        case "onepass": {
            return <img src={onepass} alt={alt} className={classname}/>;
        }
        case "sso": {
            return <img src={sso} alt={alt} className={classname}/>;
        }
        case "iam": {
            return <img src={iam} alt={alt} className={classname}/>;
        }
        case "bpm": {
            return <img src={bpm} alt={alt} className={classname}/>;
        }
        case "clouddesk": {
            return <img src={clouddesk} alt={alt} className={classname}/>;
        }
        case "tasker": {
            return <img src={tasker} alt={alt} className={classname}/>;
        }
        case "hrm": {
            return <img src={hrm} alt={alt} className={classname}/>;
        }
        case "finance": {
            return <img src={finance} alt={alt} className={classname}/>;
        }
        case "okr": {
            return <img src={okr} alt={alt} className={classname}/>;
        }
        case "vpn": {
            return <img src={vpn} alt={alt} className={classname}/>;
        }
        case "hlm": {
            return <img src={hlm} alt={alt} className={classname}/>;
        }
        case "ham": {
            return <img src={ham} alt={alt} className={classname}/>;
        }
        case "itssc": {
            return <img src={itssc} alt={alt} className={classname}/>;
        }
        case "mcssc": {
            return <img src={mcssc} alt={alt} className={classname}/>;
        }
        case "pas": {
            return <img src={pas} alt={alt} className={classname}/>;
        }
        case "im": {
            return <img src={im} alt={alt} className={classname}/>;
        }
        case "gpt": {
            return <img src={gpt} alt={alt} className={classname}/>;
        }
        case "rpa": {
            return <img src={rpa} alt={alt} className={classname}/>;
        }
        case "virtual-person": {
            return <img src={virtualPerson} alt={alt} className={classname}/>;
        }
        case "data-merge": {
            return <img src={dataMerge} alt={alt} className={classname}/>;
        }
        case "data-clean": {
            return <img src={dataClean} alt={alt} className={classname}/>;
        }
        case "data-market": {
            return <img src={dataMarket} alt={alt} className={classname}/>;
        }
        case "data-tag": {
            return <img src={dataTag} alt={alt} className={classname}/>;
        }
        case "crm": {
            return <img src={crm} alt={alt} className={classname}/>;
        }
        case "spm": {
            return <img src={spm} alt={alt} className={classname}/>;
        }
        case "scm": {
            return <img src={scm} alt={alt} className={classname}/>;
        }
        case "psm": {
            return <img src={psm} alt={alt} className={classname}/>;
        }
        case "super-shop": {
            return <img src={superShop} alt={alt} className={classname}/>;
        }
        case "web3-valley": {
            return <img src={web3Alley} alt={alt} className={classname}/>;
        }
        case "cloudy-human-resource": {
            return <img src={cloudHumanResource} alt={alt} className={classname}/>;
        }
        case "bp": {
            return <img src={bp} alt={alt} className={classname}/>;
        }
        case "lp": {
            return <img src={lp} alt={alt} className={classname}/>;
        }
        default: {
            return <img src={NotFoundImage} alt="" className={classname}/>;
        }
    }
}

export default Image;