import '../../assets/css/entity/button/layout.css';
import '../../assets/css/entity/button/element-style.css';
import ButtonRight from '../../assets/image/dot-button-right.png';

function DotButtonRight({call}) {
    return (
        <>
            <div className="dot-button dot-button-right" onClick={call}>
                <img src={ ButtonRight } alt="下一个"/>
            </div>
        </>
    );
}

export default DotButtonRight;