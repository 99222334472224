import '../../assets/css/entity/navigator/layout.css';
import '../../assets/css/entity/navigator/element.css';
import Logo from "../../assets/image/logo.png";


function NavigatorLogo({closeAllPopUp}) {
    return (
        <>
            <div
                className="navigator-0 navigator-small-screen navigator-middle-screen navigator-big-screen navigator-mega-screen"
                onMouseEnter={closeAllPopUp}
            >
                <a href="/">
                    <img className="navigator-logo" src={Logo} alt=""/>
                </a>
            </div>
        </>
    );
}

export default NavigatorLogo;