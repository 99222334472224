function FooterICP(props) {
    return (
        <span className='footer-right'>
            <p className='footer-icp'>
                ICP备案-沪B2-20200347-1 <br/>
                沪公安网备202102074号
            </p>
        </span>
    );
}

export default FooterICP;