import Navigator from "../../../entity/navigator/Navigator";

function Head({open1, close1, open2, close2}) {
    return (
        <>
            <Navigator
                open1={() => open1()}
                close1={() => close1()}
                open2={() => open2()}
                close2={() => close2()}
            />
        </>
    );
}

export default Head;